<template>
  <Loading v-if="isLoading || isLoadingVaribales" />
  <div v-else>
    <Sidebar v-if="user && profile && route.name !== 'Report'" />
    <div class="content" :class="{ main: (user && profile && route.name !== 'Report') }">
      <router-view/>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { loadVariables, isLoading as isLoadingVaribales } from './composables/getGlobalVariables'
import getUser from './composables/getUser'
import getCollection from './composables/getCollection'
import Sidebar from './components/Main/Sidebar.vue'
import Loading from './components/Main/Loading.vue'

export default {
  components: { Sidebar, Loading },
  setup() {
    const route = useRoute()
    const { user } = getUser()
    const { loadListeners, profile, isLoading } = getCollection()

    if (user.value && route.name !== 'Report') {
      loadListeners(user.value.uid)
    }

    loadVariables()

    return { route, isLoading, isLoadingVaribales, user, profile }
  }
}
</script>