import { createRouter, createWebHistory } from 'vue-router'
import { checkRoute } from '../composables/getGlobalVariables'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: checkRoute
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/Auth.vue'),
    beforeEnter: checkRoute
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    beforeEnter: checkRoute
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    beforeEnter: checkRoute
  },
  {
    path: '/create',
    name: 'Create',
    component: () => import('../views/Create.vue'),
    beforeEnter: checkRoute
  },
  {
    path: '/overview/:id',
    name: 'Overview',
    component: () => import('../views/Overview.vue'),
    beforeEnter: checkRoute,
    props: true
  },
  {
    path: '/edit/:id',
    name: 'Edit',
    component: () => import('../views/Edit.vue'),
    beforeEnter: checkRoute,
    props: true
  },
  {
    path: '/embed/:id',
    name: 'Embed',
    component: () => import('../views/Embed.vue'),
    beforeEnter: checkRoute,
    props: true
  },
  {
    path: '/report/:id',
    name: 'Report',
    component: () => import('../views/Report.vue'),
    props: true
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start()
  }

  next()
})

router.afterEach((to, from) => {
  NProgress.done()
})

export default router
