import { createApp, ref } from 'vue'
import { initFirebase, projectAuth } from './firebase/config'
import App from './App.vue'
import router from './router'
import './assets/index.css'

let app
const user = ref(null)

initFirebase().then(() => {
  user.value = projectAuth.auth.currentUser
  
  projectAuth.onAuthStateChanged(projectAuth.auth, (fbUser) => {
    user.value = fbUser
    
    if (!app) {
      app = createApp(App).use(router).mount('#app')
    }
  })
}).catch(err => console.log(err))

export { user }