const firebaseConfig = {
  apiKey: "AIzaSyBuLXEWY-NxnTK2sVEcCQ_DZwvonClHsmg",
  authDomain: "app-emojise-com.firebaseapp.com",
  projectId: "app-emojise-com",
  storageBucket: "app-emojise-com.appspot.com",
  messagingSenderId: "767444126439",
  appId: "1:767444126439:web:df3cb41cea3bace6193042",
  measurementId: "G-EB92FY1CX7"
}

let firebaseApp, projectAuth, projectFirestore, projectStorage

const initFirebase = async () => {
  if (!firebaseApp) {
    const { initializeApp } = await import('firebase/app')
    firebaseApp = initializeApp(firebaseConfig)
  }

  if (!projectAuth) {
    const { getAuth, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, 
      EmailAuthProvider,reauthenticateWithCredential, sendPasswordResetEmail, updateProfile, 
      updateEmail, updatePassword, signOut, getIdToken } = await import('firebase/auth')
    
    projectAuth = { auth: getAuth(firebaseApp), onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, 
      EmailAuthProvider, reauthenticateWithCredential, sendPasswordResetEmail, updateProfile, 
      updateEmail, updatePassword, signOut, getIdToken }
  }

  if (!projectFirestore) {
    const { getFirestore, collection, doc, 
      onSnapshot, query, where, orderBy } = await import('firebase/firestore')
    
    projectFirestore = { 
      firestore: getFirestore(firebaseApp), collection, doc, onSnapshot, query, where, orderBy 
    }
  }

  if (!projectStorage) {
    const { getStorage, ref, uploadBytes, getDownloadURL } = await import('firebase/storage')
    
    projectStorage = { 
      storage: getStorage(firebaseApp), storageRef: ref, uploadBytes, getDownloadURL 
    }
  }
}

export {
  initFirebase,  
  projectAuth,
  projectFirestore,
  projectStorage
}