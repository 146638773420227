import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'

const profile = ref(null)
const widgets = ref(null)
const error = ref(null)
const isLoading = ref(false)
const unsubProfile = ref(null)
const unsubWidgets = ref(null)

const loadListeners = async (uid) => {
  isLoading.value = true

  const { firestore, collection, doc, onSnapshot, query, where, orderBy } = projectFirestore
  let profileRef = collection(firestore, 'profiles')
  let widgetsRef = collection(firestore, 'widgets')

  profileRef = doc(profileRef, uid)
  widgetsRef = query(widgetsRef, where(`details.access`, 'array-contains', uid), orderBy('details.createdAt', 'desc'))

  unsubProfile.value = onSnapshot(profileRef, (doc) => {
    if (doc.data()) {
      profile.value = { ...doc.data(), id: doc.id }
      error.value = null
    } else {
      profile.value = null
      error.value = 'Profile doesn\'t exist'
    }

    isLoading.value = false
  }, (err) => {
    console.log(err.message)
    profile.value = null
    error.value = err.message
    isLoading.value = false
  })

  unsubWidgets.value = onSnapshot(widgetsRef, (snap) => {
    let results = []

    snap.docs.forEach(el => {
      el.data().details.createdAt && results.push({ ...el.data(), id: el.id })
    })

    widgets.value = results
    isLoading.value = false
    error.value = null
  }, err => {
    console.log(err.message)
    widgets.value = null
    error.value = err.message
    isLoading.value = false
  })
}

const getCollection = () => {
  return { profile, widgets, loadListeners, unsubProfile, unsubWidgets, error, isLoading }
}

export default getCollection