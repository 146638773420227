<template>
  <aside class="sidebar"> 
    <router-link class="company-logo" :to="{ name: 'Dashboard' }">
      <img src="@/assets/images/emojise-logo-app.png" class="icon" alt="Emojise Logo">
      <img src="@/assets/images/emojise-logo-small.png" class="mobile" alt="Emojise Logo">
    </router-link>
    <nav class="sidebar-links">
      <router-link class="link" :to="{ name: 'Dashboard' }">
        <i class="fas fa-home"></i><br>
        <span>Dashboard</span>
      </router-link>
      <router-link class="link" :class="{ danger: (profile.billing.status != 'active') }" :to="{ name: 'Account' }">
        <i class="fas fa-user"></i><br>
        <span>Account</span>
        <div v-if="profile.billing.status == 'inactive' || profile.billing.status == 'incomplete' || profile.billing.status == 'past_due'" class="tooltip sub">Your last payment failed and your subscription is currently inactive ☹️<br>Head to this page to pay the invoice manually, or update your subscription.</div>
        <div v-else-if="profile.billing.status == 'incomplete_expired' || profile.billing.status == 'canceled'" class="tooltip sub">Your subscription is over ☹️<br>To release the power of Emojise, head to this page and resubscribe to our Free or Paid plan.</div>
      </router-link>
      <span @click="handleHelp" @keyup.enter="handleHelp" class="link" tabindex="0">
        <i class="fas fa-question"></i><br>
        <span>Help</span>
      </span>
      <span @click="handleLogout" @keyup.enter="handleLogout" class="link" tabindex="0">
        <i class="fas fa-sign-out-alt"></i><br>
        <span>Log Out</span>
      </span>
    </nav>
    <span class="link usage">
      <i v-if="profile.billing.maxVotes != 'unlimited'" class="fas fa-battery-half"></i>
      <i v-else class="fas fa-battery-full"></i>
      <div v-if="profile.billing.maxVotes != 'unlimited'" class="tooltip usage">
        <h5>Monthly Usage: <i class="fas fa-info-circle" :title="`Resets on ${new Date(profile.billing.date * 1000).toLocaleDateString()}`" tabindex="0"></i></h5>
        <progress :value="profile.billing.curVotes" :max="profile.billing.maxVotes"></progress><br>
        <span>{{ profile.billing.curVotes.toLocaleString() }}/{{ profile.billing.maxVotes.toLocaleString() }} Ratings</span>
      </div>
      <div v-else class="tooltip usage unlimited">
        <h5>Monthly Usage:</h5>
        <span>UNLIMITED</span>
      </div>
    </span>
    <div class="mobile-menu">
      <router-link :to="{ name: 'Dashboard' }" class="btn"><i class="fas fa-home"></i></router-link>
      <button id="menu-btn" :class="{ tertiary: (profile.billing.status == 'active'), danger: (profile.billing.status != 'active') }" @click="showMenu = !showMenu">
        <i v-if="!showMenu" class="fas fa-user"></i>
        <i v-else class="fas fa-times"></i>
      </button>
    </div>
    <div class="dropdown-menu" :class="{ shown: showMenu }">
      <ul>
        <li v-if="profile.billing.status != 'active'" class="usage sub">
          <span v-if="profile.billing.status == 'inactive' || profile.billing.status == 'incomplete' || profile.billing.status == 'past_due'">Your last payment failed and your subscription is currently inactive ☹️<br>Head to 'Account' to pay the invoice manually, or update your subscription.</span>
          <span v-else-if="profile.billing.status == 'incomplete_expired' || profile.billing.status == 'canceled'">Your subscription is over ☹️<br>To release the power of Emojise, head to 'Account' and resubscribe to our Free or Paid plan.</span>
        </li>
        <li class="usage">
          <div v-if="profile.billing.maxVotes != 'unlimited'" class="user-usage" align="center">
            <h5>Monthly Usage: <i class="fas fa-info-circle" :title="`Resets on ${new Date(profile.billing.date * 1000).toLocaleDateString()}`" tabindex="0"></i></h5>
            <progress :value="profile.billing.curVotes" :max="profile.billing.maxVotes"></progress>
            <span>{{ profile.billing.curVotes.toLocaleString() }}/{{ profile.billing.maxVotes.toLocaleString() }} Ratings</span>
          </div>
          <div v-else class="user-usage unlimited" align="center">
            <h5>Monthly Usage:</h5>
            <span><i>UNLIMITED</i></span>
          </div>
        </li>
        <li @click="handleRoute"><i class="fas fa-user"></i><span>Account</span></li>
        <li @click="handleHelp"><i class="fas fa-question"></i><span>Help</span></li>
        <li @click="handleLogout"><i class="fas fa-sign-out-alt"></i><span>Logout</span></li>
      </ul>
    </div>
  </aside>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { Swal, Toast } from '@/composables/getGlobalVariables'
import getCollection from '@/composables/getCollection'
import useAuth from '@/composables/useAuth'

export default {
  setup() {
    const router = useRouter();
    const { profile } = getCollection();                                        
    const { logout, error } = useAuth();
    const showMenu = ref(false)

    const handleRoute = () => {
      showMenu.value = false
      router.push({ name: 'Account' })
    }

    const handleHelp = () => {
      showMenu.value = false
      $crisp.push(['do', 'chat:open'])
    }

    const handleLogout = async () => {
      showMenu.value = false
      await logout()
    
      if (!error.value) {
        router.push({ name: 'Auth' })
      } else {
        Toast.fire({
          title: 'Oops...',
          text: error.value,
          icon: 'error'
        })
      }
    }

    onMounted(async () => {
      switch (profile.value.billing.status) {
        case 'incomplete':
        case 'inactive':
        case 'past_due':
          await Swal.fire({
            title: 'Uh-oh...',
            text: 'Your last payment failed and your subscription is currently inactive ☹️\nWe may attempt payment again in the coming days.\nIn the meantime, you can pay the invoice manually, or update your subscription.',
            icon: 'error', 
            confirmButtonText: 'Reactivate Subscription'
          })

          router.push({ name: 'Account' })
          break;
        case 'canceled':
        case 'incomplete_expired':
          await Swal.fire({
            title: 'Oh, no!',
            text: 'Your subscription has been canceled ☹️\nTo unleash the power of Emojise, resubscribe to our Free or Paid plan.',
            icon: 'error', 
            confirmButtonText: 'Resubscribe'
          })
          
          router.push({ name: 'Account' })
          break;
      }
    })

    return { profile, handleLogout, handleHelp, handleRoute, showMenu }
  }
}
</script>

<style>
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 80px;
    height: 100%;
    background-color: #ffffff;
    border-right: 1px solid #e5e5e5;
    z-index: 991;
  }
  .company-logo {
    display: block;
    text-decoration: none;
    padding: 5px;
  }
  .company-logo img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  .company-logo .mobile {
    display: none;
    width: 80%;
  }
  .sidebar-links {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  .sidebar .link {
    display: block;
    text-decoration: none;
    margin: 0;
    padding: 20px 0;
    color:  var(--primary);
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid #e5e5e5;
  }
  .sidebar .link i {
    font-size: 18px;
  }
  .sidebar .link span {
    font-size: 12px;
  }
  .sidebar .link.danger i, .sidebar .link.danger span {
    color: red;
  }
  .sidebar .link.usage {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 15px 10px 10px 10px;
    border: none;
    background-color: var(--primary);
    cursor: help;
  }
  .sidebar .link.usage i:not(.fa-info-circle) {
    color: #fff;
    font-size: 20px;
  }
  .sidebar-links .link:first-child {
    border-top: 1px solid #e5e5e5;
  }
  .sidebar-links .link:hover {
    background-color: rgba(25, 25, 25, 0.1);
  }
  .sidebar-links .link.router-link-active {
    border-left: 3px solid var(--main);
  }
  .sidebar-links .link.router-link-active i, .sidebar-links .link.router-link-active span {
    color: var(--main);
  }
  .sidebar-links .link.danger.router-link-active {
    border-left: 3px solid red;
  }
  .sidebar-links .link.danger.router-link-active i, .sidebar-links .link.danger.router-link-active span {
    color: red;
  }
  .tooltip.usage h5 {
    font-size: 14px;
    margin: 5px auto;
    color: #fff;
  }
  .tooltip.usage span {
    font-size: 13px; 
    color: #fff;
  }
  .tooltip.usage.unlimited span {
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
  }
  .tooltip.usage i {
    font-size: 14px;
    color: #fff;
  }
  .tooltip.usage progress {
    width: 100%;
  }
  .sidebar .tooltip {
    visibility: hidden;
    position: absolute;
    background: var(--primary);
    border-radius: 10px;
    padding: 5px 10px;
    left: 50px;
    font-size: 14px;
    color: #fff;
  }
  .sidebar .tooltip.sub::after, .sidebar .tooltip.usage::after {
    content: " ";
    position: absolute;
    top: 30px;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent var(--primary) transparent transparent;
  }
  .sidebar .tooltip.usage::after {
    top: 55px;
  }
  .sidebar .tooltip.usage.unlimited::after {
    top: 40px;
  }
  .sidebar .tooltip.sub::after {
    border-color: transparent red transparent transparent;
  }
  .sidebar .tooltip.sub, .sidebar .tooltip.usage {
    margin-top: -60px;
    left: 85px;
  }
  .sidebar .tooltip.sub {
    width: 200px;
    background-color: red;
  }
  .sidebar .tooltip.usage {
    width: 160px;
    margin-top: -70px;
  }
  .sidebar .tooltip.usage.unlimited {
    margin-top: -55px;
  }
  .sidebar .tooltip.logout {
    width: 80px;
  }
  .sidebar .link:hover .tooltip {
    visibility: visible;
  }
  .mobile-menu {
    display: none;
    text-align: center;
  }
  .mobile-menu button, .mobile-menu .btn {
    margin: 0 5px;
    padding: 6px 10px;
    font-size: 17px;
  }
  .mobile-menu button i, .mobile-menu .btn i {
    color: #fff;
  }
  .dropdown-menu {
    visibility: hidden;
    opacity: 0;
    width: max-content;
    min-width: 150px;
    max-width: 200px;
    position: fixed;
    top: 50px;
    right: 0;
    background-color: var(--main);
    border-radius: 10px;
    box-shadow: 0 2px 5px 2px rgba(0,0,0,0.2);
    z-index: 999;
    transition: all 0.6s ease;
    transform: translateX(20px);
  }
  .dropdown-menu.shown {
    visibility: visible;
    opacity: 1;
    transform: translateX(-20px);
  }
  .dropdown-menu ul {
    padding-left: 0;
    list-style-type: none;
  }
  .dropdown-menu li {
    padding: 20px;
    font-weight: 700;
    border-bottom: 1px solid #fff;
    cursor: pointer;
  }
  .dropdown-menu li:hover {
    background-color: var(--main-hover);
  }
  .dropdown-menu li i {
    margin-right: 10px;
    width: 20px;
  }
  .dropdown-menu li.usage {
    padding: 10px 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--main);
    cursor: auto;
    text-align: center;
  }
  .dropdown-menu li.usage i {
    margin: 0;
    width: auto;
  }
  .dropdown-menu li.usage h5 {
    font-size: 15px;
  }
  .dropdown-menu li.usage span {
    font-size: 14px;
  }
  .dropdown-menu li.usage.sub {
    background-color: red;
  }
  .dropdown-menu li.usage.sub span {
    font-size: 12px;
    color: #fff;
  }
  .dropdown-menu li:last-child {
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
</style>