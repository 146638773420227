import { ref } from 'vue'
import { projectAuth } from '../firebase/config'
import getUser from './getUser'

const useFetch = () => {
  const { getIdToken } = projectAuth
  const { user } = getUser()
  const error = ref(null)
  const isLoading = ref(false)

  const getData = async (route = '', query = '', isRestricted) => {
    isLoading.value = true
    error.value = null

    const url = (!query) ? route : `${route}?${query}`
    const headers = new Headers()
    headers.append('Content-Type', 'application/json');

    try {
      if (isRestricted) {
        headers.append('Firebase-Auth', await getIdToken(user.value));
      }

      const res = await fetch(`${process.env.VUE_APP_SERVER}${url}`, {
        method: 'GET',
        headers: headers
      })
  
      const data = await res.json()
      
      if (res.status !== 200) {
        throw (data.error)
      }

      error.value = null
      isLoading.value = false
      return data
    } catch(err) {
      error.value = err.message
      isLoading.value = false
    }
  }

  const postData = async (route = '', body) => {
    isLoading.value = true
    error.value = null

    try {
      const token = await getIdToken(user.value)
      const res = await fetch(`${process.env.VUE_APP_SERVER}${route}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Firebase-Auth': token
        },
        body: JSON.stringify(body)
      })

      const data = await res.json()

      if (res.status !== 200) {
        throw (data.error)
      }

      error.value = null
      isLoading.value = false
      return data
    } catch(err) {
      error.value = err.message
      isLoading.value = false
    }
  }

  const patchData = async (route = '', body) => {
    isLoading.value = true
    error.value = null

    try {
      const token = await getIdToken(user.value)
      const res = await fetch(`${process.env.VUE_APP_SERVER}${route}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Firebase-Auth': token
        },
        body: JSON.stringify(body)
      })

      const data = await res.json()

      if (res.status !== 200) {
        throw (data.error)
      }

      error.value = null
      isLoading.value = false
      return data
    } catch(err) {
      error.value = err.message
      isLoading.value = false
    }
  }

  const deleteData = async (route = '') => {
    isLoading.value = true
    error.value = null

    try {
      const token = await getIdToken(user.value)
      const res = await fetch(`${process.env.VUE_APP_SERVER}${route}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Firebase-Auth': token
        }
      })

      const data = await res.json()

      if (res.status !== 200) {
        throw (data.error)  
      }

      error.value = null
      isLoading.value = false
      return data
    } catch(err) {
      error.value = err.message
      isLoading.value = false
    }
  }

  return { error, isLoading, getData, postData, patchData, deleteData }
}

export default useFetch