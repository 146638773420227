import { ref } from 'vue'
import { projectAuth } from '../firebase/config'
import getCollection from './getCollection'
import useFetch from './useFetch'

const { profile, widgets, loadListeners, unsubProfile, unsubWidgets } = getCollection()
const error = ref(null)
const isLoading = ref(false)

const login = async (user) => {
  error.value = null
  isLoading.value = true

  try {
    const res = await projectAuth.signInWithEmailAndPassword(projectAuth.auth, user.email, user.password)
    loadListeners(res.user.uid)
    error.value = null
    isLoading.value = false
  } catch(err) {
    error.value = 'Oops... Incorrect login credentials'
    isLoading.value = false
  }
}

const signup = async (user) => {
  error.value = null
  isLoading.value = true

  const { postData, error: accError } = useFetch()

  try {
    const res = await projectAuth.createUserWithEmailAndPassword(projectAuth.auth, user.email, user.password)
    
    await projectAuth.updateProfile(res.user, { displayName: `${user.fName} ${user.lName}` })
    
    await postData(`/users`, {
      firstName: user.fName, 
      lastName: user.lName
    })

    if (accError.value) {
      throw ({ code: 'internal-server-error' })
    }

    loadListeners(res.user.uid)
    error.value = null
  } catch(err) {
    const errMessage = (err.code === 'auth/weak-password') ? 'password must be at least 6 characters long' : err.code.split('/')[1].replace(/-/g, ' ')
    error.value = errMessage.charAt(0).toUpperCase() + errMessage.slice(1)
  } finally {
    isLoading.value = false
  }
}

const logout = async () => {
  error.value = null
  isLoading.value = true

  try {
    if (unsubProfile.value) {
      unsubProfile.value()
      profile.value = null
    }
    
    if (unsubWidgets.value) {
      unsubWidgets.value()
      widgets.value = null
    }

    await projectAuth.signOut(projectAuth.auth)
    error.value = null
  } catch(err) {
    const errMessage = err.code.split('/')[1].replace(/-/g, ' ')
    error.value = errMessage.charAt(0).toUpperCase() + errMessage.slice(1)
  } finally {
    isLoading.value = false
  }
}

const updateDetails = async (user, name, email, password) => {
  error.value = null
  isLoading.value = true

  try {
    await projectAuth.updateProfile(user, { displayName: name })
    await projectAuth.updateEmail(user, email)
    
    if (password) {
      await projectAuth.updatePassword(user, password)
    }

    error.value = null
  } catch(err) {
    console.log(err)
    const errMessage = err.code.split('/')[1].replace(/-/g, ' ')
    error.value = errMessage.charAt(0).toUpperCase() + errMessage.slice(1)
  } finally {
    isLoading.value = false
  }
}

const confirmPassword = async (user, password) => {
  error.value = null
  isLoading.value = true

  try {
    const credential = projectAuth.EmailAuthProvider.credential(user.email, password)
    await projectAuth.reauthenticateWithCredential(user, credential)
    error.value = null
    isLoading.value = false
  } catch(err) {
    console.log(err)
    const errMessage = err.code.split('/')[1].replace(/-/g, ' ')
    error.value = errMessage.charAt(0).toUpperCase() + errMessage.slice(1)
    isLoading.value = false
  }
}

const resetPass = async (email) => {
  error.value = null
  isLoading.value = true

  try {
    await projectAuth.sendPasswordResetEmail(projectAuth.auth, email)
    error.value = null
  } catch(err) {
    const errMessage = err.code.split('/')[1].replace(/-/g, ' ')
    error.value = errMessage.charAt(0).toUpperCase() + errMessage.slice(1)
  } finally {
    isLoading.value = false
  }
}

const useAuth = () => {
  return { login, signup, logout, updateDetails, confirmPassword, resetPass, isLoading, error }
}

export default useAuth