import { ref } from 'vue'
import { loadStripe } from '@stripe/stripe-js'
import getUser from '../composables/getUser'
import Swal from 'sweetalert2'

let stripe, Toast
const isLoading = ref(false)

const loadVariables = async () => {
  isLoading.value = true

  stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY)

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  isLoading.value = false
}

const checkRoute = (to, from, next) => {
  const { user } = getUser()
  
  if (!user.value) {
    if (to.name === 'Auth') {
      next()
    } else {
      next({ name: 'Auth' })
    }
  } else {
    if (to.name === 'Home' || to.name === 'Auth') {
      next({ name: 'Dashboard' })
    } else {
      next()
    }
  }
}

export { stripe, Swal, Toast, isLoading, loadVariables, checkRoute }